﻿:root {
    box-sizing: border-box;
}

[class*="col-"] {
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    width: 100%;
}

.centre {
    text-align: center;
}

.padding-10 {
    padding: 10px !important;
}

/* Try to deal with columns inside panels */
.panel .row > [class*="col-"] {
    padding-left: 0px;
    padding-top: 0px;
}

.ui.tab > .row > [class*="col-"] {
    padding-left: 0px;
}

.ui.tab {
    border-radius: 0px !important;
    background-color: #ffffff !important;
    box-shadow: 3px 3px 5px #c3c3c3 !important;
    border: 0px !important;
}

.ui.top.attached.tabular.menu {
    border: 0px !important;
}

.ui.top.attached.tabular.menu > a {
    border: 0px !important;
    border-radius: 0px !important;
    box-shadow: 3px 3px 5px #c3c3c3 !important;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}

.panel {
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 3px 3px 5px #c3c3c3;
}

footer {
    padding: 20px 0; 
    text-align: center;
    background-color: rgba(0,45,98,1);
    color: #ffffff;
}

label {
    font-size: 18px;
    margin-top: 10px;
    width: 100%;
}

h1 {
    font-size: 1.2em;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 10px;
    color: rgba(0,45,98,1);
}

p {
    margin: 10px 0 10px;
}

textarea {
    margin: 0em;
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 0.67857143em 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0;
    -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
    width: 100%;
    height: 150px;
}

.hyperlink {
    text-decoration: underline !important;
}

ul.tick-list {
    margin-top: 10px;
}

ul.tick-list > li:before {    
    font-family: 'FontAwesome';
    content: '\f05d';
    font-size: 2em;
    margin:0px 10px 0px 0px;
    color: green;
}      

ul.tick-list > li {
    text-indent: -2.3em;
    margin-left: 2.3em;
    margin-top: 5px;
}

ul.tick-list > li > span {
    vertical-align: 5px;
}

.btn {
  background: #3498db !important;
  background-image: -webkit-linear-gradient(top, #3498db, #2980b9) !important;
  background-image: -moz-linear-gradient(top, #3498db, #2980b9) !important;
  background-image: -ms-linear-gradient(top, #3498db, #2980b9) !important;
  background-image: -o-linear-gradient(top, #3498db, #2980b9) !important;
  background-image: linear-gradient(to bottom, #3498db, #2980b9) !important;
  color: #ffffff !important;
  padding: 10px 20px 10px 20px !important;
  text-decoration: none !important;
  cursor: pointer !important;
  border-radius: 0 !important;
}

.btn:hover {
  background: #3cb0fd !important;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db) !important;
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db) !important;
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db) !important;
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db) !important;
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db) !important;
  text-decoration: none;
}

.list-btn {
  border: none;
  margin: 10px 0px 10px 36px;
}

.large-text {
    font-size:1.5em;
}

/* breadcrumb */

.breadcrumb {
    padding: 10px 0px 0px 15px;
    list-style: none;
    font-size: 17px;
}

ul.breadcrumb li {
    display: inline;
}

ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: ">\00a0";
}

ul.breadcrumb li a:hover {
    color: rgba(0,45,98,1);
}

div.ui.top.attached.tabular.menu {
    display: block;
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media only screen and (min-width:320px) {  

}

/* smartphones, Android phones, landscape iPhone */
@media only screen and (min-width:480px) {  

}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media only screen and (min-width:600px) {  

}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media only screen and (min-width:801px) {
    .col-1 {width: 8.33%;}
    .col-2 {width: 33.33%;}
    .col-3 {width: 50%;}
    .col-4 {width: 33.33%;}
    .col-5 {width: 41.66%;}
    .col-6 {width: 50%;}
    .col-7 {width: 58.33%;}
    .col-8 {width: 66.66%;}
    .col-9 {width: 50%;}
    .col-10 {width: 83.33%;}
    .col-11 {width: 91.66%;}
    .col-12 {width: 100%;}

    .repeat-prescription-panel p {
        text-align: right !important;
    }

    .repeat-prescription-panel div {
        text-align: left !important;
    }

    .order-button {
        vertical-align: -30px;
    }

    .panel .row > [class*="col-"] ~ [class*="col-"],
    .tab .row > [class*="col-"] ~ [class*="col-"] {
        padding-left: 15px;
    }

    div.ui.top.attached.tabular.menu {
        display: flex;
    }
}

.order-button {
    vertical-align: -15px;
}

@media only screen and (max-width:355px) {
    .check-reg-button {
        font-size: 16px;
    }
}

@media only screen and (min-width:917px) {
    .order-button {
        vertical-align: -15px;
    }
}

/* big landscape tablets, laptops, and desktops */
@media only screen and (min-width:1025px) {  
    .col-1 {width: 8.33%;}
    .col-2 {width: 16.66%;}
    .col-3 {width: 25%;}
    .col-4 {width: 33.33%;}
    .col-5 {width: 41.66%;}
    .col-6 {width: 50%;}
    .col-7 {width: 58.33%;}
    .col-8 {width: 66.66%;}
    .col-9 {width: 75%;}
    .col-10 {width: 83.33%;}
    .col-11 {width: 91.66%;}
    .col-12 {width: 100%;}
}

/* hi-res laptops and desktops */
@media only screen and (min-width:1281px) {  

}

/* Home Page*/
.welcome-panel {
    background: url(http://www.insync-pharmacy.co.uk/wp-content/uploads/2015/07/pills.jpg);
    background-size: cover;
    height: 300px;  
    position: relative;
}

.pgwSlider > li {
    position: relative;
}

.pgwSlider .banner-link {
    position: absolute;
    bottom: 47%;
    left: 15px;
    font-size: 1.7em;
    border: 1px solid;
}

@media only screen and (min-width:420px) {
    .pgwSlider .banner-link {
        bottom: 60px;
    }
}

.pgwSlider .ps-current, .ps-list li {
    box-shadow: 3px 3px 5px #c3c3c3;
}

.pgwSlider .ps-caption {
    background: rgba(0,45,98,.8) !important;
    font-size: 1.8em;
}

ul.pgwSlider > li span, .pgwSlider > .ps-list > li span {
    background: rgba(0,45,98,.8) !important;
    font-size: 1.8em;
}

.pgwSlider .ps-current .ps-next, .pgwSlider .ps-current .ps-prev {
    background: rgba(0,45,98,.8) !important;
    top: 35% !important;
}

.pgwSlider.narrow .ps-current .ps-prev , .pgwSlider.narrow .ps-current .ps-next {
    top: 0% !important;
}

.service-panel {
    height: 200px;
    padding: 0;
    position: relative;
}

.service-panel > h1 {
    text-align: center;
    padding-top: 10px;
    border-bottom: none;
    color: #FFFFFF;
    background-color: rgba(0,45,98,.8);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.service-panel > p {
    padding : 10px;
    visibility: hidden;
    color: #FFFFFF;
    margin-top: 0px;
}

.service-panel:hover {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    box-shadow: 6px 6px 10px #c3c3c3;
    cursor: pointer;
}

.service-panel:hover > p {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    visibility: visible;
}

.registration-panel {    
    background-size: cover;
    background-image: url(../images/small/registration.jpg);
}

.registration-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/small/registration.jpg);
}

.ordering-panel {
    background-size: cover;
    background-image: url(../images/small/ordering.jpg);
    background-position: 50% 100%;
}

.ordering-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/small/ordering.jpg);
}

.advice-panel {
    background-size: cover;
    background-image: url(http://assets.wh.cdnds.net/images/5991/weight_loss-_mistakes_made_when_loosing_weight-_womens_health_uk__small_4x3.jpg);
}

.advice-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(http://assets.wh.cdnds.net/images/5991/weight_loss-_mistakes_made_when_loosing_weight-_womens_health_uk__small_4x3.jpg);
}

.cryotherapy-panel {
    background-size: cover;
    background-image: url(../images/cryotherapy.jpeg);
}

.cryotherapy-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/cryotherapy.jpeg);
}

.dossett-tray-panel {
    background-size: cover;
    background-image: url(../images/small/dossett_trays.jpg);
}

.dossett-tray-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/small/dossett_trays.jpg);
}

.travel-panel {
    background-size: cover;
    background-image: url(../images/small/travel.png);
}

.travel-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/small/travel.png);
}

.ear-wax-removal-panel {
    background-size: cover;
    background-image: url(../images/banners/ear_wax_removal_banner.png);
}

.ear-wax-removal-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/banners/ear_wax_removal_banner.png);
}

.common-ailments-panel {
    background-size: cover;
    background-image: url(../images/common_ailments.png);
}

.common-ailments-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/common_ailments.png);
}

.other-local-services-panel {
    background-size: cover;
    background-image: url(../images/small/otherservices.jpg);
}

.other-local-services-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/small/otherservices.jpg);
}

.iv-panel {
    background-size: cover;
    background-image: url(../images/small/flu.jpg);
}

.iv-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/small/flu.jpg);
}

.pharmacy-sales-panel {
    background-size: cover;
    background-image: url(../images/small/pharm_sales.jpg);
}

.pharmacy-sales-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/small/pharm_sales.jpg);
}

.careers-panel {
    background-size: cover;
    background-image: url(../images/small/careers.jpg);
}

.careers-panel:hover {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.6)
    ), url(../images/small/careers.jpg);
}

.repeat-prescription-panel {
    background-color: rgba(0,45,98,1);
    color: white;
}

.repeat-prescription-panel h1 {
    color: white;
}

.repeat-prescription-panel p {
    font-size: 1.7em;
}

.repeat-prescription-panel p, .repeat-prescription-panel div {
    text-align: center;
}

.order-col {
    height: 50px;
    margin-top: -10px;
}

.find-us {
    position: relative;
    padding-bottom: 75%; /* This is the aspect ratio */
    height: 0;
    overflow: hidden;
}

.find-us iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
    margin-top: 15px;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* About */

.first-p {
    margin-top: 0px;
}

button {
    border: none;
    margin-top: 10px;
}

/* Semantic override */


.ui.input {
    width: 100%;
    margin-bottom: 5px;
}

.ui.checkbox label {
    font-size: 18px;
}

.ui.checkbox input[type="checkbox"], .ui.checkbox {
    margin-top: 10px !important;
}

.ui.table {
    font-size: 1.5em;
}

.ui.table.compact {
    font-size: 1em;
}

.ui.dropdown .menu > .item {
    font-size: 1.5rem !important;
}

.ui.tab {
    font-size: 1em;
}

/*IE 8*/
.ie .col-1 {width: 8.33%;}
.ie .col-2 {width: 16.66%;}
.ie .col-3 {width: 25%;}
.ie .col-4 {width: 33.33%;}
.ie .col-5 {width: 41.66%;}
.ie .col-6 {width: 50%;}
.ie .col-7 {width: 58.33%;}
.ie .col-8 {width: 66.66%;}
.ie .col-9 {width: 75%;}
.ie .col-10 {width: 83.33%;}
.ie .col-11 {width: 91.66%;}
.ie .col-12 {width: 100%;}

.ie .repeat-prescription-panel p {
    text-align: right !important;
}

.ie .repeat-prescription-panel div {
    text-align: left !important;
}

.ie .order-button {
    vertical-align: -30px;
}

.ie .panel .row > [class*="col-"] ~ [class*="col-"],
.ie .tab .row > [class*="col-"] ~ [class*="col-"] {
    padding-left: 15px;
}

.ie div.ui.top.attached.tabular.menu {
    display: flex;
}

.ui .field label {
    font-size: 18px !important;
    margin-top: 10px !important;
    width: 100% !important;
    font-weight: 400 !important;
}

.field input, .field textarea, .ui .selection .dropdown, .ui.selection.dropdown > .text {
    font-size: 1.6rem !important;
    border-radius: 0 !important;
}

.ui.selection.dropdown {
    line-height: 3em;
}

.btn-register {
    margin-top: 20px !important;
}

.ui.error.message {
    font-size: 1.5rem !important;
}

.text-bold {
    font-weight: bold;
}

.text-underline {
    text-decoration: underline;
}

.contact-button {
    margin-top: 20px;
}

.mojo-link {
    font-weight: bold; 
    text-decoration: none; 
    color: #000000;
}

.footer-link {
    color: #3498db;
    font-weight: 100;
}

.ui.fluid.selection.dropdown {
    line-height: 2.5em;
}

.inline.fields .ui.input, .inline.fields .selection.dropdown {
    width: 100% !important;
}

.fields .ui.selection.dropdown {
    height: 42px !important;
}

.inline.fields .ui.radio.checkbox {
    margin-top: 0 !important;
}

.inline.fields .ui.radio.checkbox label {
    margin-top: 0 !important;
}

.inline.fields p {
    margin: 0;
    margin-right: 10px;
}

.inline.fields .ui.checked.radio.checkbox label {
    border-bottom: none !important;
}

.ui.form input[type=checkbox], .ui.form textarea {
    vertical-align: unset;
}
.ui.dropdown>.dropdown.icon:before {
    content: '\f0d7';
}
i.icon.dropdown:before {
    content: "\f0d7";
}
i.icon:before {
    background: 0 0!important;
}
*, :before, :after {
    box-sizing: border-box;
}
*, :after, :before {
    box-sizing: border-box;
}
*, :after, :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.ui.dropdown>.dropdown.icon {
    line-height: 0.5 !important;
}

.cd-primary-nav .dropdown {
    margin-top: 18px;
    margin-left: 20px;
    line-height: 1.15;
    font-size: 100%;
}