﻿:root {
  box-sizing: border-box;
}

[class*="col-"] {
  float: left;
  width: 100%;
  padding-top: 15px;
  padding-left: 15px;
}

.centre {
  text-align: center;
}

.padding-10 {
  padding: 10px !important;
}

.panel .row > [class*="col-"] {
  padding-top: 0;
  padding-left: 0;
}

.ui.tab > .row > [class*="col-"] {
  padding-left: 0;
}

.ui.tab {
  background-color: #fff !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: 3px 3px 5px #c3c3c3 !important;
}

.ui.top.attached.tabular.menu {
  border: 0 !important;
}

.ui.top.attached.tabular.menu > a {
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: 3px 3px 5px #c3c3c3 !important;
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.panel {
  background-color: #fff;
  padding: 10px;
  box-shadow: 3px 3px 5px #c3c3c3;
}

footer {
  text-align: center;
  color: #fff;
  background-color: #002d62;
  padding: 20px 0;
}

label {
  width: 100%;
  margin-top: 10px;
  font-size: 18px;
}

h1 {
  color: #002d62;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 10px;
  font-size: 1.2em;
}

p {
  margin: 10px 0;
}

textarea {
  max-width: 100%;
  -webkit-tap-highlight-color: #fff0;
  text-align: left;
  color: #000000de;
  box-shadow: none;
  width: 100%;
  height: 150px;
  background: #fff;
  border: 1px solid #22242626;
  border-radius: 0;
  outline: none;
  flex: 1 0 auto;
  margin: 0;
  padding: .678571em 1em;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  line-height: 1.21429em;
  transition: box-shadow .1s, border-color .1s;
}

.hyperlink {
  text-decoration: underline !important;
}

ul.tick-list {
  margin-top: 10px;
}

ul.tick-list > li:before {
  content: "";
  color: green;
  margin: 0 10px 0 0;
  font-family: FontAwesome;
  font-size: 2em;
}

ul.tick-list > li {
  text-indent: -2.3em;
  margin-top: 5px;
  margin-left: 2.3em;
}

ul.tick-list > li > span {
  vertical-align: 5px;
}

.btn {
  background: #3498db -moz-linear-gradient(top, #3498db, #2980b9) !important;
  background-image: -ms-linear-gradient(top, #3498db, #2980b9) !important;
  color: #fff !important;
  cursor: pointer !important;
  background-image: linear-gradient(#3498db, #2980b9) !important;
  border-radius: 0 !important;
  padding: 10px 20px !important;
  text-decoration: none !important;
}

.btn:hover {
  text-decoration: none;
  background: #3cb0fd -moz-linear-gradient(top, #3cb0fd, #3498db) !important;
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db) !important;
  background-image: linear-gradient(#3cb0fd, #3498db) !important;
}

.list-btn {
  border: none;
  margin: 10px 0 10px 36px;
}

.large-text {
  font-size: 1.5em;
}

.breadcrumb {
  padding: 10px 0 0 15px;
  font-size: 17px;
  list-style: none;
}

ul.breadcrumb li {
  display: inline;
}

ul.breadcrumb li + li:before {
  color: #000;
  content: "> ";
  padding: 8px;
}

ul.breadcrumb li a:hover {
  color: #002d62;
}

div.ui.top.attached.tabular.menu {
  display: block;
}

@media only screen and (min-width: 801px) {
  .col-1 {
    width: 8.33%;
  }

  .col-2 {
    width: 33.33%;
  }

  .col-3 {
    width: 50%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-8 {
    width: 66.66%;
  }

  .col-9 {
    width: 50%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-12 {
    width: 100%;
  }

  .repeat-prescription-panel p {
    text-align: right !important;
  }

  .repeat-prescription-panel div {
    text-align: left !important;
  }

  .order-button {
    vertical-align: -30px;
  }

  .panel .row > [class*="col-"] ~ [class*="col-"], .tab .row > [class*="col-"] ~ [class*="col-"] {
    padding-left: 15px;
  }

  div.ui.top.attached.tabular.menu {
    display: flex;
  }
}

.order-button {
  vertical-align: -15px;
}

@media only screen and (max-width: 355px) {
  .check-reg-button {
    font-size: 16px;
  }
}

@media only screen and (min-width: 917px) {
  .order-button {
    vertical-align: -15px;
  }
}

@media only screen and (min-width: 1025px) {
  .col-1 {
    width: 8.33%;
  }

  .col-2 {
    width: 16.66%;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-8 {
    width: 66.66%;
  }

  .col-9 {
    width: 75%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-12 {
    width: 100%;
  }
}

.welcome-panel {
  height: 300px;
  background: url("http://www.insync-pharmacy.co.uk/wp-content/uploads/2015/07/pills.jpg") 0 0 / cover;
  position: relative;
}

.pgwSlider > li {
  position: relative;
}

.pgwSlider .banner-link {
  border: 1px solid;
  font-size: 1.7em;
  position: absolute;
  bottom: 47%;
  left: 15px;
}

@media only screen and (min-width: 420px) {
  .pgwSlider .banner-link {
    bottom: 60px;
  }
}

.pgwSlider .ps-current, .ps-list li {
  box-shadow: 3px 3px 5px #c3c3c3;
}

.pgwSlider .ps-caption, ul.pgwSlider > li span, .pgwSlider > .ps-list > li span {
  font-size: 1.8em;
  background: #002d62cc !important;
}

.pgwSlider .ps-current .ps-next, .pgwSlider .ps-current .ps-prev {
  background: #002d62cc !important;
  top: 35% !important;
}

.pgwSlider.narrow .ps-current .ps-prev, .pgwSlider.narrow .ps-current .ps-next {
  top: 0% !important;
}

.service-panel {
  height: 200px;
  padding: 0;
  position: relative;
}

.service-panel > h1 {
  text-align: center;
  color: #fff;
  width: 100%;
  background-color: #002d62cc;
  border-bottom: none;
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.service-panel > p {
  visibility: hidden;
  color: #fff;
  margin-top: 0;
  padding: 10px;
}

.service-panel:hover {
  -o-transition: all .5s ease;
  cursor: pointer;
  transition: all .5s;
  box-shadow: 6px 6px 10px #c3c3c3;
}

.service-panel:hover > p {
  -o-transition: all .5s ease;
  visibility: visible;
  transition: all .5s;
}

.registration-panel {
  background-image: url("registration.15124f68.jpg");
  background-size: cover;
}

.registration-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("registration.15124f68.jpg");
}

.ordering-panel {
  background-image: url("ordering.40c020c9.jpg");
  background-position: 50% 100%;
  background-size: cover;
}

.ordering-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("ordering.40c020c9.jpg");
}

.advice-panel {
  background-image: url("http://assets.wh.cdnds.net/images/5991/weight_loss-_mistakes_made_when_loosing_weight-_womens_health_uk__small_4x3.jpg");
  background-size: cover;
}

.advice-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("http://assets.wh.cdnds.net/images/5991/weight_loss-_mistakes_made_when_loosing_weight-_womens_health_uk__small_4x3.jpg");
}

.cryotherapy-panel {
  background-image: url("cryotherapy.617a613e.jpeg");
  background-size: cover;
}

.cryotherapy-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("cryotherapy.617a613e.jpeg");
}

.dossett-tray-panel {
  background-image: url("dossett_trays.e6bb6690.jpg");
  background-size: cover;
}

.dossett-tray-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("dossett_trays.e6bb6690.jpg");
}

.travel-panel {
  background-image: url("travel.6bd870c4.png");
  background-size: cover;
}

.travel-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("travel.6bd870c4.png");
}

.ear-wax-removal-panel {
  background-image: url("ear_wax_removal_banner.39704c46.png");
  background-size: cover;
}

.ear-wax-removal-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("ear_wax_removal_banner.39704c46.png");
}

.common-ailments-panel {
  background-image: url("common_ailments.32060e04.png");
  background-size: cover;
}

.common-ailments-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("common_ailments.32060e04.png");
}

.other-local-services-panel {
  background-image: url("otherservices.16500b02.jpg");
  background-size: cover;
}

.other-local-services-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("otherservices.16500b02.jpg");
}

.iv-panel {
  background-image: url("flu.200cd3fb.jpg");
  background-size: cover;
}

.iv-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("flu.200cd3fb.jpg");
}

.pharmacy-sales-panel {
  background-image: url("pharm_sales.bb2e021d.jpg");
  background-size: cover;
}

.pharmacy-sales-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("pharm_sales.bb2e021d.jpg");
}

.careers-panel {
  background-image: url("careers.8d0cebcf.jpg");
  background-size: cover;
}

.careers-panel:hover {
  background-image: linear-gradient(#0009, #0009), url("careers.8d0cebcf.jpg");
}

.repeat-prescription-panel {
  color: #fff;
  background-color: #002d62;
}

.repeat-prescription-panel h1 {
  color: #fff;
}

.repeat-prescription-panel p {
  font-size: 1.7em;
}

.repeat-prescription-panel p, .repeat-prescription-panel div {
  text-align: center;
}

.order-col {
  height: 50px;
  margin-top: -10px;
}

.find-us {
  height: 0;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
}

.find-us iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.videoWrapper {
  height: 0;
  margin-top: 15px;
  padding-top: 25px;
  padding-bottom: 56.25%;
  position: relative;
}

.videoWrapper iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.first-p {
  margin-top: 0;
}

button {
  border: none;
  margin-top: 10px;
}

.ui.input {
  width: 100%;
  margin-bottom: 5px;
}

.ui.checkbox label {
  font-size: 18px;
}

.ui.checkbox input[type="checkbox"], .ui.checkbox {
  margin-top: 10px !important;
}

.ui.table {
  font-size: 1.5em;
}

.ui.table.compact {
  font-size: 1em;
}

.ui.dropdown .menu > .item {
  font-size: 1.5rem !important;
}

.ui.tab {
  font-size: 1em;
}

.ie .col-1 {
  width: 8.33%;
}

.ie .col-2 {
  width: 16.66%;
}

.ie .col-3 {
  width: 25%;
}

.ie .col-4 {
  width: 33.33%;
}

.ie .col-5 {
  width: 41.66%;
}

.ie .col-6 {
  width: 50%;
}

.ie .col-7 {
  width: 58.33%;
}

.ie .col-8 {
  width: 66.66%;
}

.ie .col-9 {
  width: 75%;
}

.ie .col-10 {
  width: 83.33%;
}

.ie .col-11 {
  width: 91.66%;
}

.ie .col-12 {
  width: 100%;
}

.ie .repeat-prescription-panel p {
  text-align: right !important;
}

.ie .repeat-prescription-panel div {
  text-align: left !important;
}

.ie .order-button {
  vertical-align: -30px;
}

.ie .panel .row > [class*="col-"] ~ [class*="col-"], .ie .tab .row > [class*="col-"] ~ [class*="col-"] {
  padding-left: 15px;
}

.ie div.ui.top.attached.tabular.menu {
  display: flex;
}

.ui .field label {
  width: 100% !important;
  margin-top: 10px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.field input, .field textarea, .ui .selection .dropdown, .ui.selection.dropdown > .text {
  border-radius: 0 !important;
  font-size: 1.6rem !important;
}

.ui.selection.dropdown {
  line-height: 3em;
}

.btn-register {
  margin-top: 20px !important;
}

.ui.error.message {
  font-size: 1.5rem !important;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.contact-button {
  margin-top: 20px;
}

.mojo-link {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}

.footer-link {
  color: #3498db;
  font-weight: 100;
}

.ui.fluid.selection.dropdown {
  line-height: 2.5em;
}

.inline.fields .ui.input, .inline.fields .selection.dropdown {
  width: 100% !important;
}

.fields .ui.selection.dropdown {
  height: 42px !important;
}

.inline.fields .ui.radio.checkbox, .inline.fields .ui.radio.checkbox label {
  margin-top: 0 !important;
}

.inline.fields p {
  margin: 0 10px 0 0;
}

.inline.fields .ui.checked.radio.checkbox label {
  border-bottom: none !important;
}

.ui.form input[type="checkbox"], .ui.form textarea {
  vertical-align: unset;
}

.ui.dropdown > .dropdown.icon:before, i.icon.dropdown:before {
  content: "";
}

i.icon:before {
  background: none !important;
}

*, :before, :after, *, :after, :before {
  box-sizing: border-box;
}

*, :after, :before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.ui.dropdown > .dropdown.icon {
  line-height: .5 !important;
}

.cd-primary-nav .dropdown {
  margin-top: 18px;
  margin-left: 20px;
  font-size: 100%;
  line-height: 1.15;
}

/*# sourceMappingURL=index.d3c69738.css.map */
